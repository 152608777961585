<template>
	<div>
		<div class="result-wrap">
			<div class="result">

				<v-layout class="list" justify-center wrap>
					<img src="/images/other_result_title.png" />
					<div v-for="(no, index) in 16">
						<img :src="`https://kbplay.s3.ap-northeast-2.amazonaws.com/assets/mydays/icon_result_${$options.filters.lpad(no)}.png`" @click="moveTo(no)" />
					</div>
				</v-layout>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
			}
		},
		watch: {

		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.$store.commit('showAppBar')

			})
		},

		filters: {
			lpad(val) {
				if (!val) return ''


				if (val.toString().length < 2) {
					val = '0' + val
				}

				return val
			}
		},

		mounted() {
			this.$store.commit('setItem', null)
		},

		methods: {
			moveTo(id) {
				$('.result-wrap').fadeOut('fast', _ => {
					this.$router.push(`/result/${id}`)
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	img {
		max-width: 100%;
	}

	.result-wrap {
		overflow-y: scroll;
		height: 100vh;
		background-color: #F9CB51;


		.result {
			padding-top: 56px;
			position:relative;

			.list {
				padding: 0 calc(42*(100vw/720)) calc(95*(100vw/720));

				& > img {
					width: calc(628*(100vw/720));
					height: intrinsic;
					margin: calc(37*(100vw/720)) 0 calc(42*(100vw/720));
				}
				div {
					width: 50%;
					padding: 0 calc(5*(100vw/720));
				}
			}
		}
	}

	@media screen and (min-width: 480px) {
		.result-wrap {

			.result {
				width: 480px;
				margin: 0 auto;
			}

			.list {
				bottom: calc(415*(480px/720));
				padding: 0 calc(27*(480px/720)) 0 calc(34*(480px/720));

				div {
					width: 50%;
					padding: 0 calc(5*(480px/720));
				}
			}
		}
	}
</style>
