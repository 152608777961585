<template>
    <div>
        <div
                v-if="!isMuted"
                v-touch="{
                	    start: () => { btnPressed = true },
                	    end: () => { btnPressed = false },
                    }"
                class="btn-wrap"
                @click="switchSound"
        >
            <v-img v-show="!btnPressed" src="/images/btn_volume.png" width="50px" />
            <v-img v-show="btnPressed" src="/images/btn_volume_press.png" width="32px" class="ml-2 mr-2" />
        </div>
        <div
                v-else
                v-touch="{
                	    start: () => { btnPressed = true },
                	    end: () => { btnPressed = false },
                    }"
                class="btn-wrap"
                @click="switchSound"
        >
            <v-img v-show="!btnPressed" src="/images/btn_volume_muted.png" width="50px" />
            <v-img v-show="btnPressed" src="/images/btn_volume_muted_press.png" width="32px" class="ml-2 mr-2" />
        </div>
<!--
        <audio ref="bgm"
               :src="dayBgm"
               @loadeddata="$event.target.play()"
               @canplay="$event.target.play()"
        />
        <audio ref="sound"
               :src="sound"
               @loadeddata="$event.target.play()"
               @ended="onSoundEnded()"
               @canplay="$event.target.play()"
        />
-->
    </div>
</template>

<script>
    export default {
    	name: 'AudioWidget',

        data() {
    		return {
    			btnPressed: false,
                apiUrl: process.env.VUE_APP_SERVER_HOST,

			    bgmAudios: {},
                soundAudios: {},
                bgmAudio: null,
                soundAudio: null,
			    titleLeafAudio: null,

                dayBgm: '',
                sound: '',

                isInit: false,
            }
        },
        computed: {

	        isMuted() {
		        return this.$store.getters.isMuted
	        },
            item() {
                return this.$store.getters.item
            },
            userAction() {
	        	return this.$store.getters.userAction
            }
        },
	    watch: {
    		item() {
                // this.setBgmVolume(1)
                // if (this.soundAudio) {
                //     this.soundAudio.pause()
                //     this.soundAudio.currentTime = 0
                // }

                console.log(this.isInit)

                if (!this.isInit) this.loadAudio()

			    if (!this.item || this.item.sequence === 0) {
			    	if (this.bgmAudio) {
			    	    this.bgmAudio.pause()
                        this.bgmAudio.currentTime = 0
                        this.bgmAudio = null
                    }
			        return
                }

			    if (this.bgmAudio) {
			        this.bgmAudio.volume = 1
                }
			    if (this.titleLeafAudio && this.titleLeafAudio.played) {
			        this.titleLeafAudio.pause();
			        this.titleLeafAudio.currentTime = 0
                    this.titleLeafAudio.src = ''
                }
                this.setDayBgm()

			    if (this.item.type === 'kakao') {
			        this.setSound('kakao_receive')
                } else if (this.item.type === 'messenger') {
			        this.setSound('message_receive')
                }

                if (this.item.audio_path) {
                    this.setTitleLeafAudio()
                }
                // this.sound = ''
                // this.loadAudio()
            },
            userAction(val) {
    			if (val) {
                    let key = ''
    				switch (val) {
                        case 'swipe':
                        	key = 'swipe'
                            break;
    				    case 'kakao':
                            key = 'kakao_send'
    				        break;
                        case 'messenger':
                            key = 'message_send'
                            break;
                        case 'image':
                            key = 'image'
                        	break;
                        case 'text':
                            key = 'text'
                            break;

                    }

                    if (!key) return

                    this.setSound(key)

                    // const audioPath = this.audios[key].path
                    // if (this.audios[key].enabled && audioPath) {
                    //     this.setBgmVolume(0.2)
                    //     this.setSoundAudio(audioPath)
                    // } else {
                    //     this.soundAudio.currentTime = 0
                    //     this.soundAudio.pause()
	                //     this.soundAudio.src = ''
                    //     // this.sound = ''
	                //     // this.soundAudio.pause()
                    //     // this.soundAudio.currentTime = 0
                    // }

                    this.$store.commit('setUserAction', '')
                }
            }
        },

        beforeCreate() {
        },

        mounted() {
    	    this.loadAudio()

            this.$eventBus.$on('INIT_AUDIO',cb => {

                if (this.isInit) return false;

                if (!Object.keys(this.bgmAudios).length) return false;

                Object.keys(this.bgmAudios).forEach(key => {

                    const audio = this.bgmAudios[key]

                    audio.play()
                    audio.pause()
                    audio.currentTime = 0
                })

                Object.keys(this.soundAudios).forEach(key => {

                    const audio = this.soundAudios[key]

                    audio.play()
                    audio.pause()
                    audio.currentTime = 0
                })

                this.titleLeafAudio = new Audio()

                this.titleLeafAudio.play()
                this.titleLeafAudio.pause()
                this.titleLeafAudio.currentTime = 0

                // if (!this.bgmAudio) {
                //     this.bgmAudio = new Audio()
                //     this.bgmAudio.loop = true
                // }
                // if (!this.soundAudio) {
                //     this.soundAudio = new Audio()
                //     this.soundAudio.addEventListener('ended', _ => {
                //         this.bgmAudio.volume = 1
                //     })
	            //     this.soundAudio.addEventListener('canplaythrough', _ => {
	            //     	this.play()
                //     })
                // }
                //
                // this.bgmAudio.play().then(_ => {
                //     this.bgmAudio.pause()
                //     this.bgmAudio.currentTime = 0
                // }).catch(e => {})
                //
                // this.soundAudio.play().then(_ => {
                //     this.soundAudio.pause()
                //     this.soundAudio.currentTime = 0
                // }).catch(e => {})


                this.isInit = true

                return true

            })
        },

        methods: {
    		switchSound() {
                this.$store.commit('setMute', !this.isMuted)
			    // $('audio').each((idx, node) => {
			    //     node.muted = this.isMuted
                // })

			    if (this.bgmAudio) {
                    this.bgmAudio.muted = this.isMuted
                }
                if (this.soundAudio) {
                    this.soundAudio.muted = this.isMuted
                }

                if (this.titleLeafAudio) {
                    this.titleLeafAudio.muted = this.isMuted
                }
            },

            loadAudio() {
                this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/audio`).then(response => {
                    // this.setSound()

                    const audios = response.data.result

                    Object.keys(audios).forEach(key => {
                    	const audio = new Audio(audios[key].path)
                        // audio.addEventListener('canplaythrough', _ => {
                        //     audio.play()
                        // })

                        if (key.startsWith('day')) {
                            audio.loop = true
                    	    this.bgmAudios[key] = audio
                        } else {
                    	    audio.addEventListener('ended', _ => {
                    	        if (this.bgmAudio) {
                                    this.bgmAudio.volume = 1
                                }
                            })
                            this.soundAudios[key] = audio
                        }
                    })

                })
            },

            setDayBgm() {

    		    const key = `day${this.item.day}`

                if (this.bgmAudio === this.bgmAudios[key]) {
                	this.bgmAudio.play().catch(e => { console.log(e) })
                    return
                }

                if (this.bgmAudio) {
                    this.bgmAudio.pause()
                    this.bgmAudio.currentTime = 0
	                this.bgmAudio = null
                }

    		    if (!this.bgmAudios[key]) return

                this.bgmAudio = this.bgmAudios[key]
                this.bgmAudio.muted = this.isMuted
                this.bgmAudio.play().catch(e => { console.log(e) })
                //
                // if (this.item && this.audios) {
                //     if (this.item.day === 0) {
                //         // this.dayBgm = ''
	            //         this.bgmAudio.pause()
                //         this.bgmAudio.currentTime = 0
	            //         this.bgmAudio.src = ''
                //     } else {
                //     	if (this.audios['day' + this.item.day].enabled) {
                //             // this.dayBgm = this.audios['day' + this.item.day].path
		        //             this.setBgmAudio(this.audios[`day${this.item.day}`].path)
                //         } else {
                //             // this.dayBgm = ''
		        //             this.bgmAudio.pause()
                //             this.bgmAudio.currentTime = 0
                //             this.bgmAudio.src = ''
                //         }
                //
                //     }
                // } else {
                //     // this.dayBgm = ''
	            //     this.bgmAudio.pause()
                //     this.bgmAudio.currentTime = 0
                //     this.bgmAudio.src = ''
                // }
            },

            setSound(key) {

    			if (this.soundAudio) {
    			    this.soundAudio.pause()
                    this.soundAudio.currentTime = 0
                    this.soundAudio = null
                }

    			if (!this.soundAudios[key]) return

	            if (this.bgmAudio) {
	                this.bgmAudio.volume = 0.2
                }
                this.soundAudio = this.soundAudios[key]
                this.soundAudio.muted = this.isMuted
                this.soundAudio.currentTime = 0
                this.soundAudio.play().catch(e => { console.log(e) })

                //
                // if (this.item && this.audios) {
                // 	if (this.item.audio_path) {
                //         // this.sound = this.item.audio_path
		        //         this.setSoundAudio(this.item.audio_path)
                //         this.setBgmVolume(0.2)
                //     } else {
                //         switch (this.item.type) {
                //             case 'kakao':
                //             	if (this.audios['kakao_receive'].enabled) {
                //                     this.setSoundAudio(this.audios['kakao_receive'].path)
                //                     this.setBgmVolume(0.2)
                //                 }
                //                 break;
                //             case 'messenger':
                //                 if (this.audios['message_receive'].enabled) {
                //                     this.setSoundAudio(this.audios['message_receive'].path)
                //                     this.setBgmVolume(0.2)
                //                 }
                //                 break;
                //         }
                //     }
                // } else {
                //     // this.sound = ''
	            //     this.soundAudio.pause()
                //     this.soundAudio.currentTime = 0
                //     this.soundAudio.src = ''
                // }
            },
	        setTitleLeafAudio() {
    			if (this.bgmAudio) {
    			    this.bgmAudio.volume = 0.2
                }
    			if (this.titleLeafAudio) {
                    this.titleLeafAudio.src = this.item.audio_path
                    this.titleLeafAudio.play().catch(e => {
                        console.log(e)
                    })
                }
            },
            // onSoundEnded() {
    		// 	this.setBgmVolume(1)
            // },
            // setBgmVolume(volume) {
            //     // $('audio').get(0).volume = volume
	        //     if (this.bgmAudio) this.bgmAudio.volume = volume
            // },
            // setBgmAudio(url) {
            //
            //     if (!this.bgmAudio) return
            //
            //     if (this.bgmAudio.src !== url) {
            //         this.bgmAudio.src = url
            //         // this.bgmAudio.load()
            //         this.bgmAudio.play()
            //     }
            // },
            // setSoundAudio(url) {
            //
    		//     if (!this.soundAudio) return
            //
            //     if (this.soundAudio.src !== url) {
            //         this.soundAudio.src = url
            //         // this.soundAudio.load()
            //         this.soundAudio.play()
            //     }
            // },
        }
    }
</script>

<style lang="scss" scoped>
    .btn-wrap { width: 50px; }
</style>
