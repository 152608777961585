<template>
    <v-app>
        <v-main>
            <v-app-bar
                    color="#F9CB51"
                    flat
                    absolute
                    v-show="appBarShow"

            >
                <div
                    v-touch="{
                	    start: () => { btnBackPressed = true },
                	    end: () => { btnBackPressed = false },
                    }"
                    class="btn-back-wrap"
                    @click="$router.back()"
                >
                    <v-img v-show="!btnBackPressed" src="/images/btn_back.png" width="50px" />
                    <v-img v-show="btnBackPressed" src="/images/btn_back_press.png" width="32px" class="ml-2 mr-2" />
                </div>
<!--                </v-app-bar-nav-icon>-->
                <v-spacer></v-spacer>
                <v-toolbar-title>
                    <v-img src="/images/top_logo.png" height="35px" contain />
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <audio-widget />
            </v-app-bar>
            <transition name="fade" class="fill-height">
                <router-view></router-view>
            </transition>
        </v-main>

        <!-- snackbar -->
        <v-snackbar
                :timeout="snackbarDuration"
                :color="snackbarColor"
                top
                v-model="showSnackbar">
            {{ snackbarMessage }}
        </v-snackbar>

        <!-- dialog confirm -->
        <v-dialog v-show="showDialog" v-model="showDialog" :persistent="dialogType=='confirm'" absolute max-width="450px">
            <v-card>
                <v-card-title>
                    <div class="headline"><v-icon v-if="dialogIcon">{{dialogIcon}}</v-icon> {{ dialogTitle }}</div>
                </v-card-title>
                <v-card-text v-html="dialogMessage"></v-card-text>
                <v-card-actions v-if="dialogType=='confirm' || dialogType=='alert'">
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click.native="dialogCancel" v-show="dialogType=='confirm'">Cancel</v-btn>
                    <v-btn color="primary" text @click.native="dialogOk">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="loader" v-if="showLoader">
            <vue-loaders name="ball-pulse-sync"></vue-loaders>
        </div>

        <a href="javascript:;" class="btn-fullscreen"></a>

    </v-app>
</template>

<script>
	import AudioWidget from "./components/widgets/AudioWidget"
	export default {
		name: 'App',

		components: {
			AudioWidget,
		},

        data() {
			return {
				btnBackPressed: false,
            }
        },
		computed: {
			showLoader() {
				return this.$store.getters.showLoader
            },
			showSnackbar: {
				get() {
					return this.$store.getters.showSnackbar;
				},
				set(val) {
					if(!val) this.$store.commit('hideSnackbar');
				}
			},
			snackbarMessage() {
				return this.$store.getters.snackbarMessage;
			},
			snackbarColor() {
				return this.$store.getters.snackbarColor;
			},
			snackbarDuration() {
				return this.$store.getters.snackbarDuration;
			},


			// dialog
			showDialog: {
				get() {
					return this.$store.getters.showDialog;
				},
				set(val) {
					if(!val) this.$store.commit('hideDialog');
				}
			},
			dialogType() {
				return this.$store.getters.dialogType
			},
			dialogTitle() {
				return this.$store.getters.dialogTitle
			},
			dialogMessage() {
				return this.$store.getters.dialogMessage ? this.$store.getters.dialogMessage.replace(/\n/g, '<br />') : ''
			},
			dialogIcon() {
				return this.$store.getters.dialogIcon
			},

            appBarShow() {
				return this.$store.getters.appBarShow
            },

            appBarTitle() {
				return this.$store.getters.appBarTitle
            },
		},

        beforeMount() {
	        const self = this

	        this.axios.interceptors.request.use(function (config) {
		        const token = self.$cookie.get('token')
		        if (token) {
			        config.headers.token = token
		        }
		        return config;
	        }, function (error) {
		        // Do something with request error
		        console.log('axios.request.error', error)
		        return Promise.reject(error);
	        });

	        // Add a response interceptor
	        this.axios.interceptors.response.use(function (response) {
		        if (response.data.code === '9901' || response.data.code === '9999') {
			        // self.$store.commit('showSnackbar',{
				    //     message: response.data.message,
				    //     color: 'error',
				    //     duration: 3000
			        // });
			        self.$router.push('/')
		        }
		        return response;
	        }, function (error) {
                if (!error.response || error.code === 'ECONNABORTED') {
                    // timeout of 0ms exceeded
                } else {
                    self.$store.commit('showSnackbar', {
                        message: error,
                        color: 'error',
                        duration: 3000
                    });
                }
		        return Promise.reject(error);
	        });
        },

        watch: {

		    $route(to) {
                if (to.path === '/agreement' || to.path.startsWith('/item')) {
                    if (this.$store.getters.items.length === 0) {
                        this.getItems()
                    }
                }
            }
        },

		mounted() {
            $(document.body).on('click', this.initAudio)

            $(".btn-fullscreen").off('click', this.onClickFullscreen).on('click', this.onClickFullscreen)

		    $(window).resize(_ => {
                const clientWidth = document.documentElement.clientWidth
                const clientHeight = document.documentElement.clientHeight


                let width = clientWidth
                if (clientHeight < clientWidth) {
                    width = clientHeight
                }
                const scale = width / 720

                this.$store.commit('setScale', scale)
            }).resize()

            if (this.$route.path === '/agreement' || this.$route.path.startsWith('/item')) {
            	if (this.$store.getters.items.length === 0) {
            	    this.getItems()
                }
            }

            if (this.$route.path === '/login' || this.$route.path === '/agreement') {
                if (!this.$store.getters.agreement) {
                    this.getAgreement()
                }
            }
        },

        methods: {

		    getItems() {
                this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/item`).then(response => {
                    this.$store.commit('setItems', response.data.result.items)
                })
            },

            getAgreement() {
                this.axios.get(`${process.env.VUE_APP_SERVER_HOST}/api/agreement`).then(response => {
                    this.$store.commit('setAgreement', response.data.result.agreement)
                })
            },

		    onClickFullscreen() {
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen().catch(e => {})
                }
            },

            initAudio() {
                this.$eventBus.$emit('INIT_AUDIO', success => {
                    if (success) {
                        $(document.body).off('click', this.initAudio)
                    }
                })

            },


	        dialogOk() {
		        this.$store.commit('dialogOk');
	        },
	        dialogCancel() {
		        this.$store.commit('dialogCancel');
	        },

	        onBtnBackPress(action) {
		        this.btnBackPressed = action === 'down'
            }
        }

	};
</script>

<style>
    body {
	    position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
    }
    pre {
        white-space: pre-wrap !important;
	    font-family: KBFGDisplayM !important;
    }
    * { font-family: KBFGDisplayM}
    .kb-color {
        color: #cea427 !important;
        caret-color: #cea427 !important;
    }
    .kb-primary-color {
        color: #ffffff !important;
        caret-color: #ffffff !important;
        background-color: #F9CB51 !important;
    }
    .fade-enter-active,
    .fade-leave-active {
        transition-property: opacity;
        transition-duration: .25s;
    }
    .fade-enter-active {
        transition-delay:
                .25s;
    }
    .fade-enter,
    .fade-leave-active {
        opacity: 0
    }
</style>


<style lang="scss">
    .progress-bar { position: absolute; bottom: 3vh; width: 100%; left: 0; padding: 20px; z-index: 100;}
    .v-progress-linear {
        background-color: rgba(191, 191, 191, 0.35) !important;
    }
    .v-progress-linear__background {
        background-color: rgba(0,0,0,0) !important;
    }
    .v-progress-linear__determinate {
        border-radius: 6px !important;
    }
    .v-app-bar { z-index: 100 !important; }
    html, body { overflow: hidden; }

    .btn-back-wrap { width: 50px;}

    .loader { position: absolute; left: 0; top: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; background: rgba(0,0,0,.3); z-index: 100;}

    .btn-fullscreen {
        position: absolute;
        right: 0;
        left: 0;
        width: 0;
        height: 0;
        visibility: hidden;
    }

    @media screen and (min-width: 768px) {
        .v-text-field input {
            font-size: 1.5em;
        }
        .v-btn span {
            font-size: 1.5em;
        }
        .v-input label {
            font-size: 1.5em;
            line-height: 0.95 !important;
        }
    }
</style>
