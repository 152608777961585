<template>
    <div class="fill-height">
        <div class="item-background">
            <v-img :src="data.background_img" width="100%" height="100%" :contain="false" />
        </div>
        <v-layout class="message-wrap" fill-height column :style="`padding: ${85*scale}px`">
            <pre class="message" v-text="data.text"
                 :style="{
					fontSize: `${35*scale}px`,
					color: data.text_decorations && data.text_decorations.color || '',
					background: data.text_decorations && data.text_decorations.background || '',
					textAlign: data.text_decorations && data.text_decorations.align || '',
            	}"
            />

            <v-layout class="answer-wrap" column justify-center>
                <div class="d-inline-block">
                    <v-layout justify-center align-center
                        v-for="(item, index) in data.item_answers"
                        class="btn-answer"
                        :style="`font-size: ${35*scale}px; margin-bottom: 25px; padding: 14px`"
                         @click="onSelect(index)"
                    >
                    {{ item.text }}
                    </v-layout>
                </div>
            </v-layout>
        </v-layout>
    </div>

</template>
<script>
    export default {
    	data() {
    		return {
            }
        },
        computed: {
            scale() {
                return this.$store.getters.scale
            }
        },
    	mounted() {

            if (this.data && this.data.item_answers) {
                this.data.item_answers.forEach(item => {
                    // item.text = item.text && item.text.replace(/\n/g, '<br />')

                    if (item.text_decorations && typeof item.text_decorations === 'string') {
                        item.text_decorations = JSON.parse(item.text_decorations)
                    }
                })
            }

            this.$store.commit('changeProgressColor', 0)
	    },
        props: [
        	'data',
        ],

        watch: {
            data: {
                deep: true,
                handler(val) {
                    $('.btn-answer').removeClass('active')
                    val.item_answers.forEach(item => {
                        // item.text = item.text && item.text.replace(/\n/g, '<br />')
                    })
                }
            }
        },
	    methods: {
    		onSelect(index) {
    			$('.btn-answer').removeClass('active')
                $(`.btn-answer:eq(${index})`).addClass('active')

                this.$store.commit('setUserAction', 'text')

                this.$emit('change', this.data.item_answers[index])
            }
	    }
    }
</script>
<style lang="scss" scoped>
    .item-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
    .message-wrap {
        position: absolute;
        display: inline-flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        color: #000000;
        max-width: 100%;
        z-index: 10;


        .message {
            font-weight: 600;
            line-height: 1.43;
        }
    }

    .answer-wrap {

        .btn-answer {
            background-image: linear-gradient(100deg, #e8ebf2, #f2f3f7);
            border: 1px solid rgba(255,255,255,0.5);
            border-radius: 1.5em;
            box-shadow: 10px 10px 10px 0 rgba(15, 41, 107, 0.12);
            font-weight: 500;

            img {
                width: 100%;
            }

            &.active {
                border: 5px solid #f9cb51;
                background-image: linear-gradient(137deg, #f5f2ea 3%, #fffef5 99%);
                padding: 10px !important;
            }
        }
    }

</style>
