var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"fill-height",attrs:{"column":""}},[_c('v-layout',{staticClass:"messenger",style:(("font-size: " + (40*_vm.scale) + "px; padding-top: 35px")),attrs:{"column":"","justify-start":"","align-center":""}},[_c('pre',{staticClass:"message-title",style:({
                        width: '80%',
                        fontSize: ((35*_vm.scale) + "px"),
                    }),domProps:{"textContent":_vm._s(_vm.data.text)}}),_c('div',{staticClass:"user",style:(("width: 80%; margin-top: " + (30*_vm.scale) + "px; font-size: " + (30*_vm.scale) + "px"))},[_c('img',{style:(("width:" + (69*_vm.scale) + "px")),attrs:{"src":"/images/kakao_icon.png"}}),_vm._v(" 김ㅇㅇ "),_c('span',{staticClass:"time",style:(("font-size:" + (24*_vm.scale) + "px"))},[_vm._v("18:00")])]),_c('pre',{staticClass:"message",style:({
                            width: '80%',
                            padding: ((20*_vm.scale) + "px " + (40*_vm.scale) + "px"),
                            fontSize: ((35*_vm.scale) + "px"),
                        }),domProps:{"textContent":_vm._s(_vm.data.message)}})]),_c('v-layout',{staticClass:"answers",style:(("font-size: " + (35*_vm.scale) + "px; padding: " + (40*_vm.scale) + "px;")),attrs:{"justify-start":"","align-center":"","column":""}},_vm._l((_vm.data.item_answers),function(item,index){return (index < 2)?_c('div',{staticClass:"btn-answer",staticStyle:{"width":"80%"},style:({
                    padding: ((24*_vm.scale) + "px"),
                    marginTop: ((index > 0 ? 21*_vm.scale : 0) + "px"),
                }),on:{"click":function($event){return _vm.onSelect(index)}}},[_c('pre',{domProps:{"textContent":_vm._s(item.text)}}),_c('button',{staticClass:"btn",style:(("font-size: " + (30*_vm.scale) + "px; padding: " + (5*_vm.scale) + "px " + (20*_vm.scale) + "px;"))},[_vm._v("전송")])]):_vm._e()}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }