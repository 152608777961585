<template>
    <div class="login-layout-wrap">
        <v-layout class="login-layout" align-center justify-center fill-height
        >
            <v-img class="portrait" width="100%" height="100vh" src="/images/background_login_portrait.png" />
            <v-img class="landscape" width="100%" height="100vh" src="/images/background_login_landscape.png" />
                <v-sheet class="login-wrap">
                    <img class="logo" src="/images/top_logo.png" />
                    <v-form ref="form"
                            class="form"
                            v-model="valid"
                            lazy-validation>
                        <v-text-field ref="email"
                                      label="아이디"
                                      type="email"
                                      dense
                                      solo
                                      flat
                                      hide-details
                                      class="input-field mt-2"
                                      color="#A59D95"
                                      v-model="email"
                        />
                        <v-text-field ref="passwd"
                                      label="패스워드"
                                      type="password"
                                      dense
                                      solo
                                      flat
                                      hide-details
                                      class="input-field mt-2"
                                      color="#A59D95"
                                      v-model="passwd"
                        />
                    </v-form>
                    <img src="/images/btn_login.png" @click="login" />
                </v-sheet>
        </v-layout>
<!--        <div class="a2hs-wrap" :class="device">-->
<!--            <div v-if="device==='ios'" class="a2hs ios">-->
<!--                <img src="/images/icon-192x192.png" />-->
<!--                <div class="message">-->
<!--                    <img src="/images/btn_ios_share.png" align="top" />를 클릭 후 [홈 화면에 추가]를 터치해 앱으로 설치하여 플레이 해주시기 바랍니다.-->
<!--                </div>-->
<!--            </div>-->
<!--            <div v-if="device==='android'" class="a2hs android">-->
<!--                <img src="/images/icon-192x192.png" />-->
<!--                <div class="message">-->
<!--                    여기를 눌러 KB MyDays 앱 설치 후 플레이 해주시기 바랍니다.-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="landing">
            <div v-if="device === 'ios'" class="ios">
                <img src="/images/landing_ios.png" />
            </div>
            <div v-if="device === 'android'" class="android">
                <img src="/images/landing_android.png" />
                <div>
                    <img class="btn-install" src="/images/btn_install.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
	    name: 'Login',

	    components: {
	    },

	    data() {
		    return {
		    	device: '',
			    valid: true,
			    email: '',
			    passwd: '',

			    emailRules: [
				    (v) => !!v || '이메일주소를 입력하세요.',
				    (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || '이메일 주소형식이 정확하지 않습니다.'
			    ],
		    }
	    },

        computed: {
            scale() {
                return this.$store.getters.scale
            }
        },

	    beforeRouteEnter(to, from, next) {
		    next(vm => {
			    vm.$store.commit('hideAppBar')
		    })
	    },

        beforeDestroy() {
            $(window).off('resize', this.onResize)
        },

	    mounted() {
            setTimeout(_ => {
                $('.login-form').fadeIn()
            }, 500)

            // 아이폰 A2HS 유도
            const isIos = () => {
                const userAgent = window.navigator.userAgent.toLowerCase();
                // return /iphone|ipad|ipod/.test( userAgent );
                return /iphone|ipod/.test( userAgent );
            }
            const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

            if (isIos() && !isInStandaloneMode()) {
                this.device = 'ios'
                // $('.a2hs-wrap').slideDown()
                $('.landing').show();
            }



            let deferredPrompt;
            // 안드로이드 앱설치 유도
            window.addEventListener('beforeinstallprompt', (e) => {
                // Prevent Chrome 67 and earlier from automatically showing the prompt
                e.preventDefault();
                // Stash the event so it can be triggered later.
                deferredPrompt = e;

                this.device = 'android'
                $('.landing').show()

                setTimeout(_ => {
                    console.log($('.btn-install'))
                    $('.btn-install').click(_ => {
                        console.log(deferredPrompt)
                        // Show the prompt
                        deferredPrompt.prompt();
                        // Wait for the user to respond to the prompt
                        deferredPrompt.userChoice.then((choiceResult) => {
                            if (choiceResult.outcome === 'accepted') {
                                console.log('User accepted the A2HS prompt');
                                deferredPrompt = null;
                                $('.a2hs-wrap').hide()
                            } else {
                                console.log('User dismissed the A2HS prompt');
                                location.reload()
                            }
                        });
                    })
                })
            });



            $(window).resize(this.onResize).resize()

        },

	    methods: {
            onResize() {
                $('.landing').height(window.innerHeight)
            },
		    login() {
			    const self = this

			    if (!this.email || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
				    self.$store.commit('showDialog',{
					    type: 'alert',
					    message: '이메일 주소를 입력해주세요.',
					    okCb: ()=>{
						    setTimeout(this.$refs.email.focus)
					    }
				    });
				    return;
			    }
			    if (!this.passwd) {
				    self.$store.commit('showDialog',{
					    type: 'alert',
		                message: '비밀번호를 입력해주세요.',
		                okCb: ()=>{
			                setTimeout(this.$refs.passwd.focus)
		                }
	                });
	                return;
                }

			    const params = {
				    email: this.email,
				    passwd: this.passwd,
			    }
			    this.axios.post(`${process.env.VUE_APP_SERVER_HOST}/api/login`, params).then(response => {
				    if (response.data.code === '9011') {

					    self.$store.commit('showDialog',{
						    type: 'alert',
						    message: response.data.message,
						    okCb: ()=>{
							    setTimeout(this.$refs.email.focus)
						    }
					    });
				    } else if (response.data.code === '9012') {

					    self.$store.commit('showDialog',{
						    type: 'alert',
						    message: response.data.message,
						    okCb: ()=>{
							    setTimeout(self.$refs.passwd.focus)
						    }
					    });
                    } else if (response.data.code === '9501' || response.data.code === '9502') {

                        self.$store.commit('showDialog',{
                            type: 'alert',
                            message: response.data.message.replace(/\\n/g, '\n'),
                            okCb: ()=>{
                            }
                        });
				    } else if (response.data.code === '0000') {
					    // self.$cookie.set('token', response.data.result.token)
                        // self.$cookie.set('name', response.data.result.name)

                        // if (document.documentElement.requestFullscreen) {
	                    //     document.documentElement.requestFullscreen()
                        // }
					    // if (document.documentElement.webkitEnterFullScreen) {
						//     document.documentElement.webkitEnterFullScreen();
					    // }
                        // this.$router.push(`/intro`)

					    self.$cookie.set('token', response.data.result.token)

                        if (response.data.result.agreed !== 'Y') { // 미동의 교육생
                            this.$router.push('/agreement')
                        } else if (response.data.result.result) { // 진단완료한 교육생
	                        // 결과 페이지로 이동
                            this.$router.push(`/result`)
                        } else {
                        	const sequence = Math.max(response.data.result.last_sequence, 0) // 최종 진행항 문항 번호
	                        this.$router.push(`/item/${sequence}`)
                        }
				    }
			    })
				    .catch(error => {
					    console.log(error)
				    })
		    }

	    }
    };
</script>

<style scoped lang="scss">
	.login-layout-wrap {
        background-color: #f9cb51;
    }
    .login-layout {
         background-color: #F9CB51 !important;
    }

    .logo-title {
        width: 100%;
        padding: 0 20px;
    }

    .login-wrap {
        position: absolute;
	    background-color: rgba(0,0,0,0);
        width: calc(425*(100vw/720));
	    top: 50%;
        margin-top: calc(240*(100vh/1280));

        .logo {
            display: none;
            margin: 0 auto;
	        width: 75%;
        }

        img {
            width: 100%;
	        margin-top: 15px;
        }
    }

    .login-form {
        width: 200px;
    }

    .input-field {
        height: 35px;
        background-color: #F4F0ED !important;
        border: unset !important;
        font-size: 16px;
    }

    .v-text-field--outlined >>> fieldset {
        border-width: 0 !important;
        background-color: #F4F0ED !important;
    }

    .landscape {
        display: none;
    }

    .portrait {
        display: block;
    }

    @media screen and (min-width: 768px) {

	    .login-layout {
            max-width: 80%;
            margin: 0 auto;
        }
    }

    @media screen and (orientation: landscape) {

        .landscape {
            display: block;
        }

        .portrait {
            display: none;
        }

        .login-wrap {
            left: 50%;
            top: 0;
	        margin-top: 0;
            margin-left: calc(100*(100vw/1280));
	        width: calc(300*(100vw/1280));
	        height: 100%;
	        display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .logo {
                display: inherit;
            }


        }
    }

    .a2hs-wrap {
        position: fixed;
        bottom: 0;
        left: 0; right: 0;
        display: none;

        &.ios {
            bottom: calc(30*100vw/720);
        }

        .a2hs {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 5px;
            background-color: #f2f8ff;
            border-radius: 10px;

            &>img {
                width: calc(90*100vw/720);
                border-radius: 7px;
            }

            .message {

                word-break: keep-all;
                padding-left: 10px;
                font-size: calc(30*100vw/720);

                img {
                    width: calc(25*100vw/720);
                }
            }

            &.ios:after {
                content: " ";
                position: absolute;
                top: 100%; /* At the bottom of the tooltip */
                left: 50%;
                margin-left: calc(-30*100vw/720);
                border-width: calc(30*100vw/720);
                border-style: solid;
                border-color: #f2f8ff transparent transparent transparent;
            }
        }
    }

    .landing {
        display: none;
        position: absolute;
        left: 0; right: 0;
        top: 0; bottom: 0;
        background-color: #DBD3CC;

        &>div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .ios {
            align-items: flex-end;
        }

        .android {
            align-items: center;

            display: flex;
            justify-content: center;
            align-items: flex-start;

            &>div {
                position: absolute;
                width: calc(425*(100vmin/720));
                top: 50%;

                img {
                    margin-top: calc(188*100vmin/720);
                }
            }
        }
    }
</style>

<style>
    .theme--light.v-text-field--solo > .v-input__control > .v-input__slot{
        background-color: #FFF !important;
    }
</style>
