<template>

    <div class="fill-height">
        <div class="item-background">
            <v-img :src="data.background_img" width="100%" height="100%" :contain="false" />
        </div>
        <div class="message-wrap">
            <pre class="message" v-text="this.data.text"
                 :style="{
                     fontSize: `${35*scale}px`,
                     color: data.text_decorations && data.text_decorations.color || '',
                     background: data.text_decorations && data.text_decorations.background || '',
                     textAlign: data.text_decorations && data.text_decorations.align || '',
                 }"
            />
            <v-btn rounded class="kb-primary-color btn-start" width="65%" x-large @click="$emit('change')">{{ data.message }}</v-btn>

        </div>
    </div>

</template>
<script>
    export default {
    	data() {
    		return {
            }
        },
        computed: {
            scale() {
                return this.$store.getters.scale
            }
        },
    	mounted() {
    		if (this.data) {
		    }
	    },
        props: [
        	'data',
        ],

        watch: {
        },
	    methods: {
	    }
    }
</script>
<style lang="scss" scoped>
    .item-background {
        width: 100%;
        height: 100%;
    }
    .message-wrap {
        position: absolute;
        top: 30px;
        bottom: 50px;
        left: 30px;
        right: 30px;
        color: #000000;
        max-width: 100%;

        .message {
            background-color: #fff;
            border-radius: 25px;
            height: calc(100% - 100px);
            padding: 30px;
        }

        .btn-start {
            margin: 25px auto 0;
            font-weight: 600;
            display: block;
            height: 55px;
        }
    }

</style>
