<template>

	<div class="fill-height">
		<div class="item-background">
			<v-img :src="data.background_img" width="100%" height="100%" :contain="false" />
		</div>
		<div class="message-wrap" :style="`padding: ${85*scale}px`">
			<pre class="message" v-text="data.text"
				:style="{
					fontSize: `${35*scale}px`,
					color: data.text_decorations && data.text_decorations.color || '',
					background: data.text_decorations && data.text_decorations.background || '',
					textAlign: data.text_decorations && data.text_decorations.align|| '',
            	}"
			/>
		</div>

		<div class="wrapper" v-touch="{
        	start: ($event) => onMoveStart($event),
            move: ($event) => onMove($event),
            end: ($event) => onMoveEnd($event),
    }">
			<div class="splitter">
				<v-img src="/images/swipe_splitter.png" width="100%" height="100%" />
			</div>
			<div class="left-item" v-if="data.item_answers && data.item_answers[0]"
			     :style="{ width: `${344*scale}px` }"
			>
				<img src="/images/swipe_left.png" :width="`${97*scale}`" />
				<pre class="item-text"
				   :style="{
					fontSize: `${35*scale}px`,
					color: data.item_answers[0].text_decorations && data.item_answers[0].text_decorations.color || '',
					background: data.item_answers[0].text_decorations && data.item_answers[0].text_decorations.background || '',
					textAlign: data.item_answers[0].text_decorations && data.item_answers[0].text_decorations.align|| '',
            	}"
				   v-text="data.item_answers[0].text"></pre>
			</div>
			<div class="right-item"
			     :style="{ width: `${344*scale}px` }"
			>
				<div style="text-align: right;">
					<img src="/images/swipe_right.png" :width="`${121*scale}`" />
				</div>
				<pre class="item-text"
				   :style="{
					fontSize: `${35*scale}px`,
					color: data.item_answers[1].text_decorations && data.item_answers[1].text_decorations.color || '',
					background: data.item_answers[1].text_decorations && data.item_answers[1].text_decorations.background || '',
					textAlign: data.item_answers[1].text_decorations && data.item_answers[1].text_decorations.align|| '',
            	}"
				   v-text="data.item_answers[1].text"></pre>
			</div>
			<div class="overlay-selector overlay-left"
			     :style="`width: ${50*scale}px; height: ${50*scale}px;`"
			></div>
			<div class="overlay-selector overlay-right"
			     :style="`width: ${50*scale}px; height: ${50*scale}px;`"
			></div>
		</div>
	</div>

</template>
<script>
	export default {
		data() {
			return {
				startX: 0,
				currentX: 0,
			}
		},
		computed: {
			scale() {
				return this.$store.getters.scale
			}
		},
		mounted() {

			if (this.data && this.data.item_answers) {
				this.data.item_answers.forEach(item => {
					// item.text = item.text && item.text.replace(/\n/g, '<br />')

					if (item.text_decorations && typeof item.text_decorations === 'string') {
						item.text_decorations = JSON.parse(item.text_decorations)
					}
				})
			}

			this.$store.commit('changeProgressColor', 0)
		},
		props: [
			'data',
		],

		watch: {
			data: {
				deep: true,
				handler(val) {
					val.item_answers.forEach(item => {
						// item.text = item.text && item.text.replace(/\n/g, '<br />')
					})
				}
			}
		},
		methods: {
			onMoveStart(event) {
				this.startX = event.touchstartX
			},
			onMove(event) {
				const direction = event.touchmoveX - event.touchstartX
				const distance = Math.abs(direction)

				this.currentX = event.touchmoveX

				if (distance > 0) {
					const size = 50 * this.scale + distance
					const opacity = distance / (document.documentElement.clientHeight - 50 * this.scale) + 0.5;

					if (direction > 0) {

						$('.overlay-left').css('width', size + 'px')
							.css('height', size + 'px')
							.css('top', `calc(25vh - ${distance / 2}px)`)
							.css('left', `calc(15% - ${distance * 1 / 3}px)`)
							.css('opacity', opacity);
					} else {

						$('.overlay-right').css('width', size + 'px')
							.css('height', size + 'px')
							.css('top', `calc(45vh - ${distance / 2}px)`)
							.css('right', `calc(15% - ${distance * 1 / 3}px)`)
							.css('opacity', opacity);
					}

				}
			},
			onMoveEnd() {
				const distance = Math.abs(this.currentX - this.startX)

				if (this.currentX > 0 && distance > 50 * this.scale) {
					if (this.currentX > this.startX) {
						this.onMoveRight()
					} else {
						this.onMoveLeft()
					}
				} else {
					this.resetOverlay()
				}
			},
			resetOverlay() {
				$('.overlay-left').removeClass('active')
					.css('width', 50*this.scale + 'px')
					.css('height', 50*this.scale + 'px')
					.css('top', 'calc(25vh)')
					.css('left', '15%')
					.css('opacity', 0)

				$('.overlay-right').removeClass('active')
					.css('width', 50*this.scale + 'px')
					.css('height', 50*this.scale + 'px')
					.css('top', 'calc(45vh)')
					.css('right', '15%')
					.css('opacity', 0)
			},
			onMoveLeft() {
				$('.overlay-right').addClass('active')

				this.$store.commit('setUserAction', 'swipe')

				setTimeout(_ => {
					setTimeout(_ => {
						this.resetOverlay()
					}, 800)
					this.$emit('change', this.data.item_answers[1])
				}, 500)
			},
			onMoveRight() {
				$('.overlay-left').addClass('active')

				this.$store.commit('setUserAction', 'swipe')

				setTimeout(_ => {
					setTimeout(_ => {
						this.resetOverlay()
					}, 800)
					this.$emit('change', this.data.item_answers[0])
				}, 500)
			},
		}
	}
</script>
<style lang="scss" scoped>
	.item-background {
		width: 100%;
		height: 100%;
	}
	.message-wrap {
		position: absolute;
		max-width: 100%;
		color: #F9CB51;
		width: 100%;
		font-weight: 600;
		top: 0;

		.message {
		}
	}
	.wrapper {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		margin-top: 22.6pt;

		.splitter {
			position: absolute;
			width: 50%;
			left: 25%;
			top: calc(30vh);
		}

		.left-item {
			position: absolute;
			top: calc(25vh);
			left: 10%;
			height: 50vw;

			& > p {
				padding-top: 10px;
			}
		}

		.right-item {
			position: absolute;
			top: calc(45vh);
			right: 10%;
			text-align: right;
			& > p {
				padding-top: 10px;
			}
		}

		.item-text {
			color: #fff;
		}
	}

	.overlay-selector {
		position: absolute;
		opacity: 0;
		border-radius: 50%;
		z-index: 10;

		&.active {
			transition: all 0.3s ease-in;
			width: 200vh !important;
			height: 200vh !important;
			opacity: 1 !important;
			z-index: 11;
		}

		&.overlay-left {
			background: #96BBFF;
			top: calc(25vh);
			left: 15%;

			&.active {
				left: -50vh !important;
				top: -50vh !important;
			}
		}

		&.overlay-right {
			background: #F9CB51;
			top: calc(45vh);
			right: 15%;

			&.active {
				right: -50vh !important;
				top: -50vh !important;
			}
		}
	}


</style>
