<template>

    <div class="fill-height">
        <div class="item-background">
            <v-img :src="data.background_img" width="100%" height="100%" :contain="false" />
        </div>

        <pre class="message" v-text="this.data.text"
             :style="{
                 fontSize: `${35*scale}px`,
                 color: data.text_decorations && data.text_decorations.color || '',
                 background: data.text_decorations && data.text_decorations.background || '',
                 textAlign: data.text_decorations && data.text_decorations.align|| '',
                 top: `6.1%`,
                 left: `19.5%`,
                 right: `19.5%`,
             }"
        />
        <div class="button-wrap" v-if="data.action_type === 'button'">
            <v-btn rounded class="kb-primary-color btn-start" width="65%" x-large @click="v => {$emit('change'); this.$store.commit('setUserAction', 'text')}">{{ data.message }}</v-btn>
        </div>
        <div class="swipe-wrap" v-if="data.action_type === 'swipe'" v-touch="{
        	start: ($event) => onMoveStart($event),
            move: ($event) => onMove($event),
            end: ($event) => onMoveEnd($event),
    }">
            <div class="right-item">
                <div style="text-align: right;">
                    <img src="/images/swipe_right.png" :width="`${121*scale}`" />
                </div>
                <pre class="item-text"
                   :style="{
					fontSize: `${35*scale}px`,
					color: data.message_decorations && data.message_decorations.color || '',
					message: data.message_decorations && data.message_decorations.message || '',
					textAlign: data.message_decorations && data.message_decorations.align|| '',
            	}"
                   v-text="data.message"></pre>
            </div>
            <div class="overlay-selector overlay-right"
                 :style="`width: ${50*scale}px; height: ${50*scale}px;`"
            ></div>
        </div>

    </div>

</template>
<script>
    export default {
    	data() {
    		return {
                startX: 0,
                currentX: 0,
            }
        },
        computed: {
            scale() {
                return this.$store.getters.scale
            }
        },
    	mounted() {
    		if (this.data) {
		    }
	    },
        props: [
        	'data',
        ],

        watch: {
        },
	    methods: {
            onMoveStart(event) {
                this.startX = event.touchstartX
            },
            onMove(event) {
                const direction = event.touchmoveX - event.touchstartX
                const distance = Math.abs(direction)

                if (direction > 0) return

                this.currentX = event.touchmoveX

                if (distance > 0) {
                    const size = 50 * this.scale + distance
                    const opacity = distance / (document.documentElement.clientHeight - 50 * this.scale) + 0.5;


                    $('.overlay-right').css('width', size + 'px')
                            .css('height', size + 'px')
                            .css('top', `calc(70vh - ${distance / 2}px)`)
                            .css('right', `calc(15% - ${distance * 1 / 3}px)`)
                            .css('opacity', opacity);

                }
            },
            onMoveEnd() {
                const distance = Math.abs(this.currentX - this.startX)

                if (this.currentX > 0 && distance > 50 * this.scale) {
                    this.onMoveLeft()
                } else {
                	this.resetOverlay()
                }
            },
            onMoveLeft() {
                $('.overlay-right').addClass('active')

                this.$store.commit('setUserAction', 'swipe')

                setTimeout(_ => {
                    setTimeout(_ => {
                        this.resetOverlay()
                    }, 800)
                    this.$emit('change')
                }, 500)
            },
            resetOverlay() {
                $('.overlay-right').removeClass('active')
                        .css('width', 50*this.scale + 'px')
                        .css('height', 50*this.scale + 'px')
                        .css('top', '70vh')
                        .css('right', '15%')
                        .css('opacity', 0)
            },
        }
    }
</script>
<style lang="scss" scoped>
    .item-background {
        width: 100%;
        height: 100%;
    }

    .message {
	    position: absolute;
	    text-align: center;
        line-height: 1.43;
    }

    .button-wrap {
        position: absolute;
        bottom: 10%;
        left: 0;
        width: 100%;
        text-align: center;

        .btn-start {
            margin: 25px auto 0;
            font-weight: 600;
            display: block;
            height: 55px;
        }
    }

    .right-item {
        position: absolute;
        top: 70vh;
        right: 10%;
        width: 60vw;
        text-align: right;
        & > p {
            padding-top: 10px;
        }
    }

    .item-text {
        color: #fff;
    }

    .swipe-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .overlay-selector {
        position: absolute;
        opacity: 0;
        border-radius: 50%;
        z-index: 10;

        &.active {
            transition: all 0.3s ease-in;
            width: 200vh !important;
            height: 200vh !important;
            opacity: 1 !important;
            z-index: 11;
        }

        &.overlay-right {
            background: #F9CB51;
            top: 70vh;
            right: 15%;

            &.active {
                right: -50vh !important;
                top: -50vh !important;
            }
        }
    }

</style>
