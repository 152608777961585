var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"fill-height",attrs:{"column":""}},[_c('v-layout',{staticClass:"popup",attrs:{"justify-center":"","align-center":""}},[_c('v-card',{staticClass:"message-popup",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"message-title",style:(("font-size: " + (30*_vm.scale-4) + "px;"))},[_c('v-spacer'),_vm._v(" MESSAGE "),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-layout',{staticClass:"message-body",style:(("padding: " + (72*_vm.scale) + "px " + (37*_vm.scale) + "px")),attrs:{"align-center":""}},[_c('img',{style:(("width: " + (69*_vm.scale) + "px")),attrs:{"src":"/images/kakao_icon.png"}}),_c('pre',{style:({
                            fontSize: ((35*_vm.scale) + "px"),
                        }),domProps:{"textContent":_vm._s(_vm.data.message)}})])],1),_c('v-card-actions',{staticClass:"message-buttons",style:(("font-size: " + (35*_vm.scale) + "px;"))},_vm._l((_vm.data.item_answers),function(item,index){return (index < 2)?_c('pre',{staticClass:"btn-answer",style:(("height: " + (174*_vm.scale) + "px")),domProps:{"textContent":_vm._s(item.text)},on:{"click":function($event){return _vm.onSelect(index)}}},[_vm._v("                ")]):_vm._e()}),0)],1)],1),_c('v-layout',{staticClass:"message",style:({
            fontSize: ((40*_vm.scale) + "px"),
            background: _vm.data.text_decorations && _vm.data.text_decorations.background || '',
            }),attrs:{"justify-center":"","align-center":""}},[_c('pre',{staticStyle:{"width":"80%","padding-bottom":"15%"},style:({
                    fontSize: ((38*_vm.scale) + "px"),
                    color: _vm.data.text_decorations && _vm.data.text_decorations.color || '',
                    textAlign: _vm.data.text_decorations && _vm.data.text_decorations.align|| '',
                }),domProps:{"textContent":_vm._s(_vm.data.text)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }